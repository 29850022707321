import axios from './instance'

// const token = 'dkGvXSnhgJHbKD5NbOHReEPP9GOYcV89PbF7J85hhET8BxnpSyg4IM2dfzTL'

export const printEtiqueta = async (guia) => {
  try {
    // const URL = 'https://bgr.epresis.com/api/v1/public/print_etiquetas.json'
    const res = await axios.get(`/print_etiquetas/${guia}`, {
      responseType: 'blob'
    })
    return res
  } catch (err) {
    throw err.response
  }
}

<template>
  <div class="assignUserContainer d-flex justify-start">
    <v-btn  @click="assignUser" color="primary">Asignar Usuario</v-btn>
  </div>
</template>

<script>
export default {
  name: 'AsignMultipleUsersButton',
  props: {
    selectedIds: Array
  },
  methods: {
    assignUser () {
      this.$emit('assignUser')
    }
  }
}
</script>

<style scoped>
.assignUserContainer {
  padding-bottom: 2em !important;
}
</style>

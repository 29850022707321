<template>
    <div>
        <FilterComponent
            :isUserAdmin="isUserAdmin"
            @filter="filter"
            @clearFilters="clearFilters"
            :isOrders="true"
        />
        <AsignMultipleUsersButton
            v-if="selectedIds.length > 0"
            :selectedIds="selectedIds"
            @assignUser="assignUser"
        />
        <AppTable
            :showSelect="isUserAdmin"
            :dataTable="dataTable"
            :headers="headers"
            :getColor="getColor"
            :elevation="'elevation-1'"
            :expanded="expanded"
            :currentPage="paginationOptions.page"
            :options.sync="tableOptions"
            :pagination.sync="paginationOptions"
            :clearSelected="clearSelected"
            @handleExpandedItem="handleExpandedItem"
            @updateOptions="updateTable"
            @handleState="handleSelectedMenuItem"
            @getMainOrder="getMainOrder"
            @idsSelected="updateSelectedIds"
            @doneClearingSelected="doneClearingSelected"
        >
            <!-- <template v-slot:selectedColumnTable="slotProps">
        <div>hey</div>
        <v-checkbox
          :item="slotProps.item"
        ></v-checkbox>
      </template> -->
            <template v-slot:paginationTable>
                <Pagination
                    :pagination="paginationOptions"
                    :options.sync="paginationOptions"
                    @updateOptions="updateTable"
                />
            </template>
            <template v-slot:iconInsideColumn="slotProps">
                <component
                    :is="setIcon(slotProps.item.id_sales_channel)"
                    class="mr-4"
                />
            </template>
            <template v-slot:selectedColumnTable>
                <div>hey</div>
            </template>
            <template v-slot:columnExpandTable="slotProps">
                <ColumnExpand
                    :item="slotProps.item"
                    :isExpanded="slotProps.isExpanded"
                    :expand="slotProps.expand"
                    :selectedMenuItem="selectedMenuItem"
                    :itemsMenuList="itemsMenuList"
                    @handleSelectedMenuItem="handleSelectedMenuItem"
                />
            </template>

            <template v-slot:expandedItemTable="slotProps">
                <ExpandedItem
                    :item="slotProps.item"
                    :headers="slotProps.headers"
                    :expandHeaders="expandHeaders"
                />
            </template>
        </AppTable>
        <StateDialog
            :item="editedItem"
            :dialog="showDialog"
            @setStatus="updateOrder"
            @closeDialog="closeDialog"
        ></StateDialog>
        <UserEditDialog
            :item="editedItem"
            :dialog="showDialogUser"
            @setUser="updateOrder"
            @closeDialog="closeDialog"
        ></UserEditDialog>
        <ComentaryDialog
            :item="editedItem"
            :dialog="showDialogComentary"
            @setCommentary="updateOrder"
            @closeDialog="closeDialog"
        ></ComentaryDialog>
        <AssignUserDialog
            :item="editedItem"
            :dialog="showDialogAssignUser"
            :usersItems="usersItems"
            :selectedIds="selectedIds"
            @setAssignedUser="updateOrder"
            @setMultipleAssignedUsers="updateMultipleOrders"
            @closeDialog="closeDialog"
        ></AssignUserDialog>
    </div>
</template>

<script>
import AppTable from "@/components/views/expandTable/AppTable.vue";
import Pagination from "@/components/views/expandTable/tableComponents/Pagination.vue";
import ColumnExpand from "@/components/views/expandTable/tableComponents/ColumnExpand.vue";
import ExpandedItem from "@/components/views/expandTable/tableComponents/ExpandedItem.vue";
import { MeliIcon, WooIcon } from "@/assets/icons";
import SalePreview from "@/components/preview/SalePreview.vue";
import StateDialog from "@/components/preview/StateDialog.vue";
import UserEditDialog from "@/components/orders/UserEditDialog.vue";
import ComentaryDialog from "@/components/orders/ComentaryDialog.vue";
import AssignUserDialog from "@/components/orders/AssignUserDialog.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import {
    getAllOrders,
    updateStatus,
    updateUser,
    updateCommentary,
    asignUser,
    asignMultipleUsers,
    generatePDF,
} from "@/services/orders.js";
import { getAllUsers } from "@/services/users";
import { printEtiqueta } from "@/services/eflet";

import FilterComponent from "./Filter.vue";
import AsignMultipleUsersButton from "./AsignMultipleUsersButton.vue";
import { EventBus } from "@/Event-bus.js";

export default {
    name: "OrdersTable",
    components: {
        AppTable,
        Pagination,
        ColumnExpand,
        ExpandedItem,
        MeliIcon,
        WooIcon,
        SalePreview,
        StateDialog,
        UserEditDialog,
        ComentaryDialog,
        AssignUserDialog,
        FilterComponent,
        AsignMultipleUsersButton,
    },
    props: {
        isUserAdmin: Boolean,
    },
    data: () => ({
        expanded: [],
        singleExpand: false,
        selectedMenuItem: null,
        itemsMenuList: [
            { text: "Cambiar Estado", fn: "showChangeStatus" },
            { text: "Vista Previa", fn: "showPreviewData" },
            { text: "Copiar Enlace", fn: "copyLink" },
            { text: "Comentario Interno", fn: "showDialogCommentary" },
            { text: "Modificar Datos Cliente", fn: "showChangeClientData" },
            { text: "Imprimir etiqueta e-Flet", fn: "printTicketEflet" },
            { text: "Generar PDF", fn: "generateOrderPDF" },
        ],
        expandHeaders: [
            { text: "Producto", value: "product_name", align: "left" },
            { text: "Nombre", value: "name", align: "left" },
            { text: "Apellido", value: "last_name", align: "left" },
            { text: "Personaje", value: "icon", align: "left" },
            { text: "Color", value: "color", align: "left" },
            { text: "Cantidad", value: "quantity", align: "center" },
            { text: "Variacion", value: "variants", align: "left" },
            { text: "comentario", value: "commentary", align: "left" },
            { text: "Precio", value: "unit_amount", align: "center" },
            { text: "Total", value: "product_total", align: "center" },
        ],
        headers: [
            { text: "Numero", value: "number", align: "left" },
            { text: "Correo", value: "client.email", align: "left" },
            { text: "Fecha", value: "created_at", align: "left" },
            { text: "Estado", value: "state", align: "left" },
            { text: "Envío", value: "shipping_line", align: "left" },
            { text: "Total", value: "total_amount", align: "center" },
            { text: "Asignado a", value: "assigned_client", align: "center" },
            { text: "", value: "data-table-preview", align: "right" },
            { text: "", value: "data-table-expand", align: "right" },
        ],
        dataTable: [],
        tableOptions: {
            page: 1,
            itemsPerPage: 30,
        },
        paginationOptions: {
            page: 1,
            itemsPerPage: 30,
            pageStart: 0,
            pageStop: 30,
            itemsLength: 30,
        },
        search: "",
        filters: {
            date: "",
            status: "",
            user: "",
        },
        allOrders: [],
        allFiltered: [],
        usersItems: [],
        editedItem: null,
        editedIndex: -1,
        showPreview: null,
        showDialog: false,
        showDialogUser: false,
        showDialogComentary: false,
        showDialogAssignUser: false,
        selectedIds: [],
        clearSelected: false,
        // currentPage: 0
    }),

    computed: {
        ...mapState(["userToken", "textSearch"]),
        ...mapGetters(["userData", "getTextSearch", "getOrdersFilters"]),
    },

    watch: {
        /* textSearch (newValue) {
      const searchRegex = new RegExp(newValue, 'i')
      this.dataTable = this.allFiltered.filter((event) => {
        if (!newValue || searchRegex.test(event.id_external) || searchRegex.test(event.email)) return event

        if (event.products_order && event.products_order.length > 0) {
          for (let i = 0; i < event.products_order.length; i++) {
            if (searchRegex.test(event.products_order[i].name) || searchRegex.test(event.products_order[i].last_name)) return event
          }
        }
      })
    }, */
        paginationOptions: {
            handler() {
                this.tableOptions.page = this.paginationOptions.page;
            },
        },
    },

    created() {
        this.filters = this.getOrdersFilters;
        this.search = this.getTextSearch;
        EventBus.$on("doSearch", async () => {
            this.search = this.getTextSearch;
            this.handleLoading(true);
            await this.getOrders(
                1,
                this.search,
                this.filters.date,
                this.filters.status,
                this.filters.user
            );
            await this.handleExpandedItem();
            this.handleLoading(false);
        });
        this.initialize();
    },

    methods: {
        ...mapActions([
            "signout",
            "handleLoading",
            "handleAlert",
            "handleOrdersFilters",
        ]),

        async initialize() {
            this.handleLoading(true);
            const date = this.filters.date !== [] ? this.filters.date : "";
            await this.getOrders(
                1,
                this.search,
                date,
                this.filters.status,
                this.filters.user
            );
            await this.handleExpandedItem();
            // await this.setPage()
            if (this.isUserAdmin) {
                this.itemsMenuList.push({
                    text: "Asignar",
                    fn: "showDialogAssingUser",
                });
                await this.getUsers();
            }
            this.handleLoading(false);
        },

        async getOrders(page, q, dates, state, user) {
            try {
                const res = await getAllOrders(
                    this.userToken,
                    page,
                    q,
                    dates,
                    state,
                    user
                );
                this.allOrders = this.formatDataTable(res.data.data);
                this.dataTable = this.allOrders;
                this.updatePagination(
                    page,
                    res.data.total,
                    res.data.from,
                    res.data.to
                );
            } catch (error) {
                this.handleMessageErrorApi(error);
            }
        },

        async getUsers() {
            try {
                this.handleLoading(true);
                const res = await getAllUsers(this.userToken);
                this.usersItems = this.formatUsersItems(res.data);
            } catch (error) {
                this.handleMessageErrorApi(error);
            }
        },
        formatDataTable(data) {
            if (Array.isArray(data)) {
                data.forEach((e) => {
                    this.assignData(e);
                });
            } else {
                this.assignData(data);
            }
            return data;
        },
        assignData(e) {
            // e.selected = false
            e.email = e.client.email;
            e.assigned_client =
                e.id_user === null ? "" : e.user.name + " " + e.user.last_name;
            e.products_order.forEach((v) => {
                v.name = v.product.customizable ? v.name : "no personalizable";
                v.last_name = v.product.customizable
                    ? v.last_name
                    : "no personalizable";
                v.icon = v.product.customizable
                    ? v.icon.replace(" ", "-").toLowerCase().trim()
                    : "no personalizable";
                v.color = v.product.customizable
                    ? v.color
                    : "no personalizable";
                v.variant = v.product.variant
                    ? v.product.variant.replace(";", "\n")
                    : "";
                v.product_name = v.product.name;
                v.product_total =
                    parseFloat(v.unit_amount) * parseFloat(v.quantity);
            });
        },
        formatUsersItems(data) {
            // resuelto con 2 arrays unidos para que queden los diseñadores primero
            const array = [{ value: 0, text: "Sin usuario asignado" }];
            const array2 = [];
            data.forEach((u) => {
                if (u.roles[0].name === "Diseñador") {
                    array.push({
                        value: u.id,
                        text:
                            u.name +
                            " " +
                            u.last_name +
                            " - " +
                            u.roles[0].name,
                    });
                } else {
                    array2.push({
                        value: u.id,
                        text:
                            u.name +
                            " " +
                            u.last_name +
                            " - " +
                            u.roles[0].name,
                    });
                }
            });
            return [...array, ...array2];
        },
        assignUserItemData(u) {
            u.value = u.name + "" + u.last_name + " " + u.role.name;
        },

        handleSelectedMenuItem(val) {
            this.editedItem = val.itemPreview;
            this.editedIndex = this.dataTable.indexOf(val.itemPreview);
            this[val.item.fn](); // llamo a la funcion de cada item seteada
        },
        showChangeStatus() {
            this.showDialog = true;
        },
        showPreviewData() {
            this.$router.push({ path: `/preview/${this.editedItem.id}` });
        },
        copyLink() {
            let baseurl = null;
            if (process.env.VUE_APP_API_URL_MODE === "development") {
                baseurl = process.env.VUE_APP_ADMIN_URL_DEV;
            } else {
                baseurl = process.env.VUE_APP_ADMIN_URL_PROD;
            }
            var aux = document.createElement("input");
            aux.setAttribute("value", `${baseurl}/order/${this.editedItem.id}`);
            document.body.appendChild(aux);
            aux.select();
            document.execCommand("copy");
            document.body.removeChild(aux);
            this.handleMessageSuccessApi("Enlace copiado al portapapeles");
        },
        showDialogCommentary() {
            this.showDialogComentary = true;
        },
        showChangeClientData() {
            this.showDialogUser = true;
        },
        showDialogAssingUser() {
            this.showDialogAssignUser = true;
        },
        async printTicketEflet() {
            if (this.editedItem.bgr_eflet_guia) {
                this.handleLoading(true);
                const response = await printEtiqueta(
                    this.editedItem.bgr_eflet_guia
                );

                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    "e-flet-" + this.editedItem.id_external + ".pdf"
                );
                document.body.appendChild(link);
                link.click();

                this.handleLoading(false);
            } else {
                this.alertColor = "error";
                this.alertMessage =
                    "Este pedido no tiene asociado correo e-flet";
                this.handleAlert({
                    value: true,
                    text: this.alertMessage,
                    color: this.alertColor,
                });
            }
        },
        async generateOrderPDF() {
            try {
                this.handleLoading(true);
                const res = await generatePDF(
                    this.editedItem.id_external,
                    this.userToken
                );
                this.handleMessageSuccessApi(res.data.message);
            } catch (error) {
                this.handleMessageErrorApi(error);
            } finally {
                this.handleLoading(false);
            }
        },
        /* setPage () {
      if (localStorage.getItem('currentPageSales')) {
        this.currentPage = Number(localStorage.getItem('currentPageSales'))
      } else {
        this.currentPage = 1
      }
    }, */

        async updateTable(paginationData) {
            this.handleLoading(true);
            await this.getOrders(
                paginationData.page,
                this.search,
                this.filters.date,
                this.filters.status,
                this.filters.user
            );
            await this.handleExpandedItem();
            // await this.setPage()
            // localStorage.setItem('currentPageSales', page.page)
            this.handleLoading(false);
        },
        updateSelectedIds(ids) {
            this.selectedIds = ids;
        },
        async assignUser() {
            this.handleLoading(true);
            this.showDialogAssingUser();
            this.handleLoading(false);
        },
        async getMainOrder(order) {
            /* this.handleLoading(true)
      await this.getOrders(1, order, this.filters.date, this.filters.status, this.filters.user)
      await this.handleExpandedItem()
      // await this.setPage()
      // localStorage.setItem('currentPageSales', page.page)
      this.handleLoading(false) */
            EventBus.$emit("changeTextSearch", order);
        },

        updatePagination(page, lastPage, pageStart, pageStop) {
            this.paginationOptions.page = page;
            this.paginationOptions.itemsLength = lastPage;
            this.paginationOptions.pageStart = pageStart - 1;
            this.paginationOptions.pageStop = pageStop;
        },

        handleExpandedItem(val) {
            this.expanded = this.allOrders;
        },

        handleMessageSuccessApi(message) {
            this.alertColor = "success";
            this.alertMessage = message;
            this.handleAlert({
                value: true,
                text: this.alertMessage,
                color: this.alertColor,
            });
        },

        handleMessageErrorApi(error) {
            this.alertColor = "error";
            if (error?.status) {
                if (error.status === 401) {
                    this.signout();
                    this.alertMessage = "Sesión caducada";
                } else {
                    if (error.status === 422) {
                        const firstError = Object.keys(error.data.errors)[0];
                        this.alertMessage = error.data.errors[firstError][0];
                    } else {
                        this.alertMessage = error.status;
                    }
                }
            } else {
                this.alertMessage = "No se pudo obtener código de error.";
            }
            this.handleAlert({
                value: true,
                text: this.alertMessage,
                color: this.alertColor,
            });
        },
        async updateOrder(params) {
            try {
                this.handleLoading(true);
                if (params.dialog === "state") {
                    const res = await updateStatus(
                        params.order,
                        this.userToken
                    );
                    Object.assign(
                        this.dataTable[this.editedIndex],
                        this.formatDataTable(res.data.order)
                    );
                    this.closeDialog(params.dialog);
                    this.handleMessageSuccessApi(res.data.message);
                } else if (params.dialog === "user") {
                    const data = await this.formatForUpdateUser(params.order);
                    const res = await updateUser(
                        params.order.client.id,
                        data,
                        this.userToken
                    );
                    Object.assign(
                        this.dataTable[this.editedIndex],
                        this.formatDataTable(res.data.order)
                    );
                    this.closeDialog(params.dialog);
                    this.handleMessageSuccessApi(res.data.message);
                } else if (params.dialog === "commentary") {
                    const res = await updateCommentary(
                        params.order,
                        this.userToken
                    );
                    Object.assign(
                        this.dataTable[this.editedIndex],
                        this.formatDataTable(res.data.order)
                    );
                    this.closeDialog(params.dialog);
                    this.handleMessageSuccessApi(res.data.message);
                } else if (params.dialog === "assignUser") {
                    const res = await asignUser(
                        params.order.id_user,
                        params.order.id,
                        this.userToken
                    );
                    Object.assign(
                        this.dataTable[this.editedIndex],
                        this.formatDataTable(res.data.order)
                    );
                    this.handleMessageSuccessApi(res.data.message);
                }
            } catch (error) {
                this.handleMessageErrorApi(error);
            } finally {
                if (params.dialog === "assignUser") {
                    this.closeDialog(params.dialog);
                }
                this.handleLoading(false);
            }
        },
        async updateMultipleOrders(params) {
            try {
                this.handleLoading(true);
                const res = await asignMultipleUsers(
                    params.userId,
                    params.selectedIds,
                    this.userToken
                );
                res.data.orders.forEach((o) => {
                    Object.assign(
                        this.dataTable[
                            this.dataTable.findIndex((i) => i.id === o.id)
                        ],
                        this.formatDataTable(o)
                    );
                });
                this.handleMessageSuccessApi(res.data.message);
            } catch (error) {
                this.handleMessageErrorApi(error);
            } finally {
                this.clearSelectedUsers();
                this.closeDialog(params.dialog);
                this.handleLoading(false);
            }
        },
        clearSelectedUsers() {
            this.clearSelected = true;
        },
        doneClearingSelected() {
            this.clearSelected = false;
        },
        formatForUpdateUser(data) {
            return {
                name: data.client.name,
                last_name: data.client.last_name,
                email: data.client.email,
                client_address: data.client_address,
                client_city: data.client_city,
                client_province: data.client_Province,
                client_postcode: data.client_postcode,
                id_order: data.id,
            };
        },
        closeDialog(dialog) {
            dialog === "state"
                ? (this.showDialog = false)
                : dialog === "user"
                ? (this.showDialogUser = false)
                : dialog === "assignUser"
                ? (this.showDialogAssignUser = false)
                : (this.showDialogComentary = false);
        },

        getColor(value) {
            let result = "";
            if (value.state.id === 5) {
                result =
                    value.shipping.includes("Retiro oficina") ||
                    value.shipping.includes("Retiro por local")
                        ? "Pedido listo para retirar"
                        : "Pedido listo para despachar";
            } else {
                result = value.state.name;
            }
            const colors = {
                Aprobado: "rgba(232, 0, 237, 1)",
                "En produccion": "rgba(255, 114, 0, 0.35)",
                "Pedido listo para retirar": "rgba(0, 237, 137, 1)",

                "Pedido listo para despachar": "rgba(0, 150, 137, 1)",
                "Pedido casi listo": "rgba(142, 0, 237)",
                Cancelado: "rgba(237, 71, 0, 1)",
                "Pedido retirado": "#a6baed",
            };
            return colors[result] || "rgba(255, 114, 0, 0.35)";
        },

        setIcon(value) {
            const icon = {
                Meli: "MeliIcon",
                Woo: "WooIcon",
            };
            value = value === 2 ? "Woo" : "Meli";
            return icon[value] || "div";
        },

        async filter() {
            this.handleLoading(true);
            this.filters = this.getOrdersFilters;
            /* this.getOrdersFilters.status === 0
        ? this.filters.status = ''
        : this.filters.status = this.getOrdersFilters.status
      this.getOrdersFilters.date.length === 0
        ? this.filters.date = ''
        : this.filters.date = this.getOrdersFilters.date.replace(' - ', ',')
      this.getOrdersFilters.user === 0
        ? this.filters.user = ''
        : this.filters.user = this.getOrdersFilters.user */

            await this.getOrders(
                1,
                this.search,
                this.filters.date,
                this.filters.status,
                this.filters.user
            );
            await this.handleExpandedItem();
            // await this.setPage()
            // localStorage.setItem('currentPageSales', page.page)
            // this.dataTable = this.allFiltered
            this.handleLoading(false);
        },

        async clearFilters() {
            this.handleLoading(true);
            this.filters.date = "";
            this.filters.status = 0;
            this.filters.user = 0;
            this.handleOrdersFilters({ date: "", status: 0, user: 0 });
            await this.getOrders(
                1,
                this.search,
                this.filters.date,
                this.filters.status,
                this.filters.user
            );
            await this.handleExpandedItem();
            this.handleLoading(false);
        },

        /* filterState (params, row) {
      if (params.state) {
        if (row.order_status.id === params.state) return true
      } else {
        return true
      }
    } */
    },
};
</script>

<style lang="scss" scoped></style>

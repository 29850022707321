<template>
  <div :class="$vuetify.breakpoint.xs ? 'mx-4 mt-4' : 'mx-8 mt-8'">
    <OrdersTable :isUserAdmin="isAdmin" />
  </div>
</template>

<script>
import OrdersTable from '@/components/orders/OrdersTable'
import { mapGetters } from 'vuex'

export default {
  name: 'Orders',

  data: () => ({
    isAdmin: false
  }),

  components: {
    OrdersTable
  },

  mounted () {
    if (this.userRoles) {
      this.userRoles.some(r => r.id === 1)
        ? this.isAdmin = true
        : this.isAdmin = false
    }
  },

  computed: {
    ...mapGetters(['userData', 'userRoles'])
  },

  watch: {
    userRoles: {
      handler () {
        if (this.userRoles) {
          this.userRoles.some(r => r.id === 1)
            ? this.isAdmin = true
            : this.isAdmin = false
        }
      }
    }
  }
}
</script>

<style scoped>
</style>

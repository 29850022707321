<template>
  <v-row justify="center">
    <v-dialog
      v-model="controlOpenDialog"
      scrollable
      persistent
      max-width="313px"
      height="373"
    >
      <v-card>
        <v-card-title>Asignar usuario</v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="item!=null || selectedIds.length > 0">
          <v-autocomplete
            autofocus
            return-object
            :clearable="userSelected.value !== 0"
            v-model="userSelected"
            :items="usersItems"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray darken-1"
            text
            @click="close(false)"
          >
            Cancelar
          </v-btn>
          <v-btn v-if="selectedIds.length > 0"
            color="pink darken-1"
            :text="true"
            :style="'primary'"
            @click="$emit('setMultipleAssignedUsers', { userId: userSelected.value, selectedIds: selectedIds, dialog: 'assignUser' })"
          >
            Guardar
          </v-btn>
          <v-btn v-else
            color="pink darken-1"
            :text="true"
            :style="'primary'"
            @click="$emit('setAssignedUser', { order: item, dialog: 'assignUser' })"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'AssignUserDialog',
  props: {
    dialog: Boolean,
    item: { type: Object, default: () => ({ userSelected: { value: 0, text: '' } }) },
    usersItems: Array,
    selectedIds: Array
  },
  data: () => ({
    userSelected: { value: 0, text: '' }
  }),
  methods: {
    close (val) {
      this.$emit('closeDialog', 'assignUser')
    }
  },
  watch: {
    userSelected: {
      handler () {
        if (this.userSelected !== null) {
          if (this.item !== null) {
            this.item.id_user = this.userSelected.value
          }
        } else {
          this.userSelected = { value: 0, text: '' }
        }
        /* console.log('selected', this.item)
        this.item !== null
          ? this.userSelected !== null
            ? this.item.id_user = this.userSelected.value
            : this.userSelected = { value: 0, text: '' }
          : '' */
      }
    },
    item: {
      handler () {
        if (this.item !== null) {
          if (this.item.user !== null) {
            this.userSelected = {
              value: this.item.user.id,
              text: ''
            }
          } else {
            this.userSelected = {
              value: 0,
              text: ''
            }
          }
        }
      }
    }
  },
  computed: {
    controlOpenDialog: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('handleDialog', value)
      }
    }
  }
}
</script>

<template>
    <v-card class="mb-3" elevation="0">
        <v-row align="center" style="height: 100%">
            <v-col class="" align-self="center">
                <v-row class="pl-4" justify="start">
                    <v-menu
                        :class="{ 'col-3': isUserAdmin }"
                        class="align-self-center"
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        hide-details
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="dateRangeText"
                                class="col-3 align-self-center"
                                label="Rango de fechas"
                                append-icon="mdi-calendar"
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date" no-title range scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(date)"
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                    <v-select
                        class="pl-3 align-self-center"
                        :class="{ 'col-3': isUserAdmin }"
                        label="Estado"
                        v-model="status"
                        :items="statusItems"
                        item-text="name"
                        item-value="id"
                        hide-details
                    >
                    </v-select>
                    <v-select
                        v-if="isUserAdmin"
                        class="pl-3 align-self-center"
                        :class="{ 'col-3': isUserAdmin }"
                        label="Usuario"
                        v-model="user"
                        :items="userItems"
                        item-text="name"
                        item-value="id"
                        :disabled="userNotAssigned"
                        hide-details
                    >
                    </v-select>
                    <v-checkbox
                        v-if="isUserAdmin"
                        class="pl-3 align-self-center"
                        color="primary"
                        :class="{ 'col-3': isUserAdmin }"
                        label="Sin usuario asignado"
                        v-model="userNotAssigned"
                        hide-details
                    >
                    </v-checkbox>
                </v-row>
            </v-col>
            <v-col
                :class="{ 'col-2': isUserAdmin }"
                align-self="center"
                style="text-align: left"
            >
                <v-btn
                    text
                    color="white"
                    plain
                    style="background-color: #eda6ad"
                    @click="filterArray"
                >
                    Filtrar
                </v-btn>
                <v-btn
                    text
                    plain
                    color="#EDA6AD"
                    @click="clearFilters"
                    class="font-weight-regular"
                >
                    Limpiar
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { getAllStatus } from "@/services/status.js";
import { getAllUsers } from "@/services/users.js";
import moment from "moment";

export default {
    data: () => ({
        status: 0,
        date: [moment().format("YYYY-MM-DD")],
        user: 0,
        formattedDate: [],
        dateForDb: "",
        // date: [moment().subtract(20, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        statusItems: [],
        userItems: [],
        menu: false,
        userNotAssigned: false,
    }),
    props: {
        isUserAdmin: Boolean,
    },
    created() {
        this.getStatus();
        this.setFiltersFromState();
        // this.filterArray()
    },
    methods: {
        ...mapActions(["signout", "handleAlert", "handleOrdersFilters"]),

        async getStatus() {
            try {
                const res = await getAllStatus(this.userToken);
                this.statusItems = res.data;
            } catch (error) {
                this.handleMessageErrorApi(error);
            }
        },

        async getUsers() {
            try {
                const res = await getAllUsers(this.userToken);
                this.userItems = [];
                res.data.forEach((u) =>
                    this.userItems.push({
                        id: u.id,
                        name: u.name + " " + u.last_name,
                    })
                );
            } catch (error) {
                this.handleMessageErrorApi(error);
            }
        },

        handleMessageErrorApi(error) {
            this.alertColor = "error";
            if (error.status === 401) {
                this.signout();
                this.alertMessage = "Sesión caducada";
            } else {
                if (error.status === 422) {
                    const firstError = Object.keys(error.data.errors)[0];
                    this.alertMessage = error.data.errors[firstError][0];
                } else {
                    this.alertMessage = error.status;
                }
            }
            this.handleAlert({
                value: true,
                text: this.alertMessage,
                color: this.alertColor,
            });
        },

        filterArray() {
            if (
                this.status === 0 &&
                Array.isArray(this.date) &&
                this.date.length === 0 &&
                this.user === 0
            ) {
            } else {
                this.handleOrdersFilters({
                    status: this.status,
                    date: this.date,
                    user: this.user,
                });
                this.$emit("filter");
            }
        },

        setFiltersFromState() {
            const { date, status, user } = this.getOrdersFilters;
            this.date = date !== "" ? date : [];
            this.status = status;
            this.user = user;
        },

        clearFilters() {
            if (this.status !== 0 || this.date.length > 1 || this.user !== 0) {
                this.date = [];
                this.status = "";
                this.user = "";
                this.userNotAssigned = false;
                this.handleOrdersFilters({ status: "", date: "", user: "" });
                this.$emit("clearFilters");
            } else {
            }
        },

        formatDates(datesArray) {
            if (datesArray[0] < datesArray[1]) {
                this.dateForDb =
                    moment(datesArray[0]).format("YYYY-MM-DD") +
                    " - " +
                    moment(datesArray[1]).format("YYYY-MM-DD");
                this.formattedDate =
                    moment(datesArray[0]).format("DD/MM/YYYY") +
                    " - " +
                    moment(datesArray[1]).format("DD/MM/YYYY");
            } else {
                this.dateForDb =
                    moment(datesArray[1]).format("YYYY-MM-DD") +
                    " - " +
                    moment(datesArray[0]).format("YYYY-MM-DD");
                this.formattedDate =
                    moment(datesArray[1]).format("DD/MM/YYYY") +
                    " - " +
                    moment(datesArray[0]).format("DD/MM/YYYY");
            }
            return this.formattedDate;
        },
    },
    computed: {
        ...mapState(["userToken"]),
        ...mapGetters(["getOrdersFilters"]),

        dateRangeText() {
            return this.date.length > 1 ? this.formatDates(this.date) : "";
        },
    },
    watch: {
        isUserAdmin: {
            handler() {
                if (this.isUserAdmin === true) {
                    this.getUsers();
                }
            },
        },
        userNotAssigned: {
            handler() {
                if (this.userNotAssigned) {
                    this.user = null;
                } else {
                    this.user = 0;
                }
            },
        },
    },
};
</script>

<style scoped lang="scss">
.v-card {
    background-color: $bgBody !important;
}
</style>

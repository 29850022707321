<template>
  <v-row justify="center">
    <v-dialog
      v-model="controlOpenDialog"
      scrollable
      persistent
      max-width="313px"
      height="373"
    >
      <v-card>
        <v-card-title>Modificar datos cliente</v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="item!=null">
          <v-text-field
            placeholder="Nombre"
            label="Nombre"
            v-model="item.client.name"
          />
          <v-text-field
            placeholder="Apellido"
            label="Apellido"
            v-model="item.client.last_name"
          />
          <v-text-field
            placeholder="Email"
            label="Email"
            v-model="item.client.email"
          />
          <v-text-field
            placeholder="Dirección"
            label="Dirección"
            v-model="item.client_address"
          />
          <v-text-field
            placeholder="Ciudad"
            label="Ciudad"
            v-model="item.client_city"
          />
          <v-text-field
            placeholder="Provincia"
            label="Provincia"
            v-model="item.client_Province"
          />
          <v-text-field
            placeholder="Código postal"
            label="Código postal"
            v-model="item.client_postcode"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray darken-1"
            text
            @click="close(false)"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="pink darken-1"
            :text="true"
            :style="'primary'"
            @click="$emit('setUser', { order: item, dialog: 'user' })"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'UserEditDialog',
  props: {
    dialog: Boolean,
    item: {
      type: Object,
      default: () => ({
        client: {
          last_name: '',
          email: ''
        },
        client_address: '',
        client_city: '',
        client_Province: '',
        client_postcode: ''
      })
    }
  },

  methods: {
    close (val) {
      this.$emit('closeDialog', 'user')
    }
  },

  computed: {
    controlOpenDialog: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('handleDialog', value)
      }
    }
  }
}
</script>
